import React from 'react';
import Loading from 'react-loading';
import { isMobile } from 'react-device-detect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IconButton from '@mui/material/IconButton';
import moment from 'moment';
import Draggable from 'react-draggable';
import CustomToolTip from '../Common/CustomToolTip';
import Records from '../Records/Records';
import classNames from 'classnames';
import '../Common/Common.scss';





class NotificationsModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      boxHeader: {
        title: '',
        headerClass: '',
        icon: null,
        iconClass: '',
      },
      bodyStyle: {color: 'black'},
      boxData: null,
      tabsHeader: [],
      tabsContent: [],
      showTabContent: null,
      refreshOnClose: false,
      width: null,
      boxStyle: null,

    }

  }

  componentWillUnmount() {
    this.props.close && this.props.close();
  }

  handleModalClose = () => {
    this.props.close && this.props.close();
  };

  openModalWindow = () => {
    this.setState({ open: true });
  }

  render() {


    if (!this.props || !this.props.open || !this.props.data || !this.props.data.loaded) {
      return '';
    } else {
      let style = {color: 'black'};
      if (this.props.defaultPosition) {
        style = Object.assign({}, style, this.props.defaultPosition);
      }
      if (this.state.width && !isMobile) {
        style = Object.assign({}, style, { minWidth: this.state.width });
      }
      if (this.state.height) {
        style = Object.assign({}, style, { minHeight: this.state.height });
      }
      if (this.state.boxStyle) {
        style = Object.assign({}, style, this.state.boxStyle);
      }

      return (
        <Draggable cancel=".formDialog">
          <div className="dialog-box">
            <div className={'box-header'}>{this.renderControls()}</div>
            <div className={"box-body"} style={this.state.bodyStyle} onScroll={e => e.stopPropagation()}>
              <div style={Object.assign({}, { minHeight: 0 })}>
                {this.renderRecords(this.props.data)}
              </div>
            </div>
          </div>
        </Draggable>
      );
    }
  }

  renderControls() {

    return (
      <div className="box-controllers">
        <div className="header-icon">
          <CustomToolTip icon={this.props.data.icon} iconClass={this.props.data.iconClass} />
        </div>
        <span className="dialogbox-title">{this.props.data.title ? this.props.data.title : 'Notifications'}</span>
        <div className="controllers-right">
          <IconButton
            className="controller close-button"
            aria-label="close"
            size="small"
            onClick={() => {
              this.state.refreshOnClose && this.props.onSuccess();
              this.props.close();
            }}
          >
            <FontAwesomeIcon icon="times" color="#fff" />
          </IconButton>
        </div>
      </div>
    );
  }

  renderRecords(data) {
    if (!data) {
      console.warn('NotificationsModal: Empty data');
      return null;
    }
    let rendered_records = <Records data={data} refreshState={this.props.reload && this.props.reload()} />;

    return <div className="records">{rendered_records}</div>;
  }



}

export default NotificationsModal;

