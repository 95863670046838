import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../actions/GlobalActions';
import Header from '../components/AppLayout/Header';

export const HeaderPage = props => {
  console.log("Header page");
  console.log(props);
  console.log('-----');
  return (
    <Header
      globalActions={props.actions}
      displayData={props.displayData}
      calendarConfig={props.data.data && props.data.data.calendar.config}
      tabData={props.tabData}
      notificationsData={props.data.data.notifications}
      currentPage={props.currentPage}
      isLoggedIn={props.data.auth.loggedIn}
      auth={props.data.auth}
      checkingCookie={props.checkingCookie}
      view={props.view}
      admin={props.admin}
    />
  );
};

function mapStateToProps(state) {
  // console.log("Header container, mapping state to props:");
  // console.log(state);
  // console.log('-----');
  return { data: state.APIReducer, view: state.ViewReducer };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderPage);
