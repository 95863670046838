import React from 'react';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare, faSquare } from '@fortawesome/free-regular-svg-icons'

import classNames from 'classnames';

const iconsSVG = require('../../constants/iconsSVG.js');
const iconTypes = require('../../constants/iconTypes.js');
library.add(faCheckSquare);
library.add(faSquare);

export default class CustomToolTip extends React.Component {
  getSVG(icon) {
    let render_svg;
    // let style = {
    //   width:'20px',
    //   height:'20px'
    // };
    // if(this.props.iconOnly) {
    //   style = {
    //     height: '30px',
    //     width: '30px',
    //     fontSize: '30px',
    //     boxShadow: '2px 2px 5px #c3c3c3',
    //     borderRadius: '50%',
    //   }
    // }
    // if(this.props.largeIcon){
    //   style = {
    //     fontSize: '34px',
    //   }
    // }
    const _icon_classes = classNames(this.props.className, this.props.iconClass);
    switch (icon) {
      case iconTypes.ASTERISK:
        return <FontAwesomeIcon icon="asterisk" className={_icon_classes} />;
      case iconTypes.BULLHORN:
        return <FontAwesomeIcon icon="bullhorn" className={_icon_classes} />;
      case iconTypes.CALENDAR:
        return <FontAwesomeIcon icon="calendar" className={_icon_classes} />;
      case iconTypes.COMMENT:
        return <FontAwesomeIcon icon="comment" className={_icon_classes} />;
      case iconTypes.CERTIFICATE:
        return <FontAwesomeIcon icon="certificate" className={_icon_classes} />;
      case iconTypes.CLOUD:
        return <FontAwesomeIcon icon="cloud" className={_icon_classes} />;
      case iconTypes.COG:
        return <FontAwesomeIcon icon="cogs" className={_icon_classes} />;
      case iconTypes.DASHBOARD:
        return <FontAwesomeIcon icon="tachometer-alt" className={_icon_classes} />;
      case iconTypes.DOWNLOAD:
        return <FontAwesomeIcon icon="download" className={_icon_classes} />;
      case iconTypes.DOWNLOAD_ALT:
        return <FontAwesomeIcon icon="file-download" className={_icon_classes} />;
      case iconTypes.CLOUD_DOWNLOAD:
        return <FontAwesomeIcon icon="cloud-download-alt" className={_icon_classes} />;
      case iconTypes.CHECKED_BOX:
        return <FontAwesomeIcon icon={["far","square-check"]} className={_icon_classes} />;
      case iconTypes.EYE_OPEN:
        return <FontAwesomeIcon icon="eye" className={_icon_classes} />;
      case iconTypes.FILE:
        return <FontAwesomeIcon icon="file-alt" className={_icon_classes} />;
      case iconTypes.FIRE:
        return <FontAwesomeIcon icon="fire" className={_icon_classes} />;
      case iconTypes.FILTER:
        return <FontAwesomeIcon icon="filter" className={_icon_classes} />;
      case iconTypes.FLASH:
        return <FontAwesomeIcon icon="bolt" className={_icon_classes} />;
      case iconTypes.FLAG:
        return <FontAwesomeIcon icon="flag" className={_icon_classes} />;
      case iconTypes.FOLDER_CLOSE:
        return <FontAwesomeIcon icon="folder" className={_icon_classes} />;
      case iconTypes.FOLDER_OPEN:
        return <FontAwesomeIcon icon="folder-open" className={_icon_classes} />;
      case iconTypes.HAND_UP:
        return <FontAwesomeIcon icon="hand-point-up" className={_icon_classes} />;
      case iconTypes.HAND_DOWN:
        return <FontAwesomeIcon icon="hand-point-down" className={_icon_classes} />;
      case iconTypes.HAND_LEFT:
        return <FontAwesomeIcon icon="hand-point-left" className={_icon_classes} />;
      case iconTypes.HAND_RIGHT:
        return <FontAwesomeIcon icon="hand-point-right" className={_icon_classes} />;
      case iconTypes.HOURGLASS:
        return <FontAwesomeIcon icon="hourglass-half" className={_icon_classes} />;
      case iconTypes.INBOX:
        return <FontAwesomeIcon icon="inbox" className={_icon_classes} />;
      case iconTypes.LIST_ALT:
        return <FontAwesomeIcon icon="list-alt" className={_icon_classes} />;
      case iconTypes.LOCK:
        return <FontAwesomeIcon icon="lock" className={_icon_classes} />;
      case iconTypes.MINUS:
        return <FontAwesomeIcon icon="minus" className={_icon_classes} />;
      case iconTypes.MENU:
        return <FontAwesomeIcon icon="bars" className={_icon_classes} />;
      case iconTypes.MENU_LEFT:
        return <FontAwesomeIcon icon="angle-left" className={_icon_classes} />;
      case iconTypes.MENU_RIGHT:
        return <FontAwesomeIcon icon="angle-right" className={_icon_classes} />;
      case iconTypes.MENU_DOWN:
        return <FontAwesomeIcon icon="angle-down" className={_icon_classes} />;
      case iconTypes.MENU_UP:
        return <FontAwesomeIcon icon="angle-up" className={_icon_classes} />;
      case iconTypes.OK:
        return <FontAwesomeIcon icon="check" className={_icon_classes} />;
      case iconTypes.OPEN_BOX:
        return <FontAwesomeIcon icon={["far","square"]} className={_icon_classes} />;
      case iconTypes.PUSHPIN:
        return <FontAwesomeIcon icon="thumbtack" className={_icon_classes} />;
      case iconTypes.REFRESH:
        return <FontAwesomeIcon icon="sync-alt" className={_icon_classes} />;
      case iconTypes.RANDOM:
        return <FontAwesomeIcon icon="random" className={_icon_classes} />;
      case iconTypes.SAVE:
        return <FontAwesomeIcon icon="save" className={_icon_classes} />;
      case iconTypes.SCALE:
        return <FontAwesomeIcon icon="scale" className={_icon_classes} />;
      case iconTypes.STAR:
        return <FontAwesomeIcon icon="star" className={_icon_classes} />;
      case iconTypes.STAR_EMPTY:
        return <FontAwesomeIcon icon="star" className={_icon_classes} />;
      case iconTypes.SEARCH:
        return <FontAwesomeIcon icon="search" className={_icon_classes} />;
      case iconTypes.TIME:
        return <FontAwesomeIcon icon="clock" className={_icon_classes} />;
      case iconTypes.TRASH:
        return <FontAwesomeIcon icon="trash-alt" className={_icon_classes} />;
      case iconTypes.THUMBS_UP:
        return <FontAwesomeIcon icon="thumbs-up" className={_icon_classes} />;
      case iconTypes.THUMBS_DOWN:
        return <FontAwesomeIcon icon="thumbs-down" className={_icon_classes} />;
      case iconTypes.TAGS:
        return <FontAwesomeIcon icon="tags" className={_icon_classes} />;
      case iconTypes.TASKS:
        return <FontAwesomeIcon icon="tasks" className={_icon_classes} />;
      case iconTypes.USER:
        return <FontAwesomeIcon icon="user" className={_icon_classes} />;
      case iconTypes.WARNING_SIGN:
        return <FontAwesomeIcon icon="exclamation-circle" className={_icon_classes} />;
      case iconTypes.ZOOM_IN:
        return <FontAwesomeIcon icon="search-plus" className={_icon_classes} />;
      case iconTypes.ZOOM_OUT:
        return <FontAwesomeIcon icon="search-minus" className={_icon_classes} />;
      case iconTypes.ADD:
        return <FontAwesomeIcon icon="plus-circle" className={_icon_classes} />;
      case iconTypes.ALERT:
        return <FontAwesomeIcon icon="exclamation-triangle" className={_icon_classes} />;
      case iconTypes.APPARATUS:
        render_svg = iconsSVG.APPARATUS;
        break;
      case iconTypes.APPROVE:
        render_svg = iconsSVG.APPROVE;
        break;
      case iconTypes.BID:
        render_svg = iconsSVG.BID;
        break;
      case iconTypes.CALLOUT:
        render_svg = iconsSVG.CALLOUT;
        break;
      case iconTypes.CANCEL:
        render_svg = iconsSVG.CANCEL;
        break;
      case iconTypes.CLOCKIN:
        render_svg = iconsSVG.CLOCKIN;
        break;
      case iconTypes.CLOCKOUT:
        render_svg = iconsSVG.CLOCKOUT;
        break;
      case iconTypes.CREATE:
        render_svg = iconsSVG.CREATE;
        break;
      case iconTypes.CROSSSTATION:
        render_svg = iconsSVG.CROSSSTATION;
        break;
      case iconTypes.DELETE:
        render_svg = iconsSVG.DELETE;
        break;
      case iconTypes.DAY:
        render_svg = iconsSVG.DAY;
        break;
      case iconTypes.DENY:
        render_svg = iconsSVG.DENY;
        break;
      case iconTypes.ENVELOPE_OPEN:
        render_svg = iconsSVG.ENVELOPE_OPEN;
        break;
      case iconTypes.EDIT:
        return <FontAwesomeIcon icon="edit" className={_icon_classes} />;
      case iconTypes.ENVELOPE:
        return <FontAwesomeIcon icon="envelope" className={_icon_classes} />;
      case iconTypes.EVENT:
        render_svg = iconsSVG.EVENT;
        break;
      case iconTypes.EXCHANGE:
        render_svg = iconsSVG.EXCHANGE;
        break;
      case iconTypes.FULL:
        render_svg = iconsSVG.FULL;
        break;
      case iconTypes.HELP:
        return <FontAwesomeIcon icon="question-circle" className={_icon_classes} />;
      case iconTypes.INFO:
        return <FontAwesomeIcon icon="info-circle" className={_icon_classes} />;
      case iconTypes.MONTH:
        render_svg = iconsSVG.MONTH;
        break;
      case iconTypes.OFFER:
        render_svg = iconsSVG.OFFER;
        break;
      case iconTypes.OFFER2:
        render_svg = iconsSVG.OFFER2;
        break;
      case iconTypes.ONDUTY:
        render_svg = iconsSVG.ONDUTY;
        break;
      case iconTypes.ONDUTYPOLICE:
        render_svg = iconsSVG.ONDUTYPOLICE;
        break;
      case iconTypes.ONSHIFT_BADGE:
        render_svg = iconsSVG.ONSHIFT_BADGE;
        break;
      case iconTypes.OVERTIME:
        render_svg = iconsSVG.OVERTIME;
        break;
      case iconTypes.PARTIAL:
        render_svg = iconsSVG.PARTIAL;
        break;
      case iconTypes.PAYPERIOD:
        render_svg = iconsSVG.PAYPERIOD;
        break;
      case iconTypes.PLUS:
        return <FontAwesomeIcon icon="plus-circle" className={_icon_classes} />;
      case iconTypes.PTO:
        render_svg = iconsSVG.PTO;
        break;
      case iconTypes.REPORTS:
        render_svg = iconsSVG.REPORTS;
        break;
      case iconTypes.STATION:
        render_svg = iconsSVG.STATION;
        break;
      case iconTypes.SETUP:
        // Could be also "wrench"
        return <FontAwesomeIcon icon="tools" className={_icon_classes} />;
      case iconTypes.SCHEDULED_DUTY:
        render_svg = iconsSVG.SCHEDULED_DUTY;
        break;
      case iconTypes.SHIFT:
        render_svg = iconsSVG.SHIFT;
        break;
      case iconTypes.SHIFTBOARD:
        return <FontAwesomeIcon icon="exchange-alt" className={_icon_classes} />;
      case iconTypes.SIGNUP:
        render_svg = iconsSVG.SIGNUP;
        break;
      case iconTypes.SIGNUP_EVENT:
        render_svg = iconsSVG.SIGNUP_EVENT;
        break;
      case iconTypes.SUB:
        render_svg = iconsSVG.SUB;
        break;
      case iconTypes.TIME_CHANGE:
        render_svg = iconsSVG.TIME_CHANGE;
        break;
      case iconTypes.TIMECLOCK_RECORD:
        render_svg = iconsSVG.TIMECLOCK_RECORD;
        break;
      case iconTypes.TIMEOFF:
        render_svg = iconsSVG.TIMEOFF;
        break;
      case iconTypes.VIEW:
        return <FontAwesomeIcon icon="eye" className={_icon_classes} />;
      case iconTypes.WEEK:
        render_svg = iconsSVG.WEEK;
        break;
      default:
        render_svg = null;
        break;
    }

    if (this.props.largeIcon) {
      return (
        <img
          className={classNames('svg-img', this.props.iconClass)}
          src={render_svg}
          alt={icon}
          style={{ width: '34px', height: '34px' }}
        />
      );
    }
    return <img className={classNames('svg-img', this.props.iconClass)} alt={icon} src={render_svg} />;
  }

  render() {
    const customClass = this.props.largeIcon ? '' : 'customToolTip ';
    if (!this.props.icon) {
      return null;
    }
    const sx = this.props.sx ? this.props.sx : {};

    return (
      <div 
        className={classNames(customClass, this.props.extraClass)}
        style={this.props.iconOnly ? { width: '30px', maxWidth: '40px' } : sx }
      >
        <abbr title={this.props.text}>{this.getSVG(this.props.icon)}</abbr>
      </div>
    );
  }
}
