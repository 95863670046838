import React from 'react';
import NewWindow from 'react-new-window'


class Modal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    }
  }


  handleModalClose = () => {
    this.props.onClose && this.props.onClose();
    this.setState({ open: false });
  };

  openModalWindow = () => {
    this.setState({ open: true });
  }

  render() {
    const { open } = this.state;
    const size_in_chars = this.props.size ?? 30;
    const sx = {width: `${size_in_chars}ch`};

    return (
      <div>
        { this.props.img && (
          <button style={{border: 'none'}} onClick={this.openModalWindow}> <img style={{margin: '0 auto'}} src={require('../../img/'+this.props.img)} /> </button>
        )}
        { this.props.label && (
          <button style={sx} className={this.props.class} onClick={this.openModalWindow}>{this.props.label}</button>
        )}
        { open && (
          <NewWindow
              key={"modal" + this.props.name}
              title={this.props.label}
              url={this.props.url}
              onUnload={this.handleModalClose} 
          />
        )}
      </div>
    );
  }
}

export default Modal;

